import React, { Component } from 'react';
import { ReloadPage } from '../pages';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { getWebSocketTokenRemote } from '../../API';
import { getRemoteSocket, getDisplaySocket } from '../../WS';
import { setup } from '../../config';
import './App.css';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            WSToken             : false,
            webSocket           : false,
            webSocketd          : false,
            WSReady             : false,
            WSError             : false,
            APIerror            : false,
            APIerrorInfo        : false,
            mustReload          : false,
            mustReloadSC        : false,
            lastSockewtMS       : false
        };
        this.setAPIError            = this.setAPIError.bind( this );
        this.refreshPage            = this.refreshPage.bind( this );
    }
    
    refreshPage( mode ) {
        this.setState({ mustReload : mode });
    }
    
    refreshScreen( mode ) {
        console.log( 'refreshScreen', mode )
        this.setState({ mustReloadSC : mode });
    }
    
    refreshToken() {
        getWebSocketTokenRemote().then( ( response ) => {   
            this.setState({ 
                WSToken     : response.data.token,
                webSocket   : getRemoteSocket( this, response.data.token ),
                webSocketD  : getDisplaySocket( this, response.data.token )
            });
        }).catch( ( error ) => { 
            this.props.setAPIError( error );
            this.setState({ 
                load : true,
            });
        });
    }

    componentDidMount() {
        this.refreshToken(); 
    }

    setAPIError( error ) {
        this.setState({ APIerror : true, APIerrorInfo : error });
    }
    
    render_regular_mode() {

        const { webSocket, WSReady, mustReload, mustReloadSC, lastSockewtMS } = this.state;

        return (
            <div>
                <Switch>
                    <Route path={ '/' } render={ 
                        ({ match, history }) => 
                            <ReloadPage
                                match           = { match } 
                                history         = { history } 
                                webSocket       = { webSocket }
                                WSReady         = { WSReady }
                                setAPIError     = { this.setAPIError }
                                mustReload      = { mustReload }
                                mustReloadSC    = { mustReloadSC }
                                lastSockewtMS   = { lastSockewtMS }
                            />
                        } 
                    />
                </Switch>
            </div>
        )
    }
    
    resetAppConnection() {

    }

    setErrorPop( errCopy, addinfo ) {
        let addinfoCP = ( addinfo ) ? <p><em> { addinfo.toJSON().message } </em></p> : '';
        return(
            <div className={ 'error supersymetric' }>
                <h3>Error</h3>
                <p>{ errCopy }</p>
                { addinfoCP }
                <a href={ '/' } className={ 'atoms regular-button inline oversized' }>
                    <span>{ 'Reload' }</span>
                </a>
            </div>
        )
    }

    render() {
        const { isSearch, WSError, APIerror, APIerrorInfo } = this.state;
        const appClass = ( isSearch ) ? ' search-mode' : ' regular-mode';

        let error = ( WSError ) ? this.setErrorPop( 'Connection to screens is lost', false ) : false;
        let errorAPI = ( APIerror ) ? this.setErrorPop( 'Connection to data is lost', APIerrorInfo ) : false;
        let errorClass = ( error || errorAPI ) ? ' ws-error' : ' no-error';
        let errorCont = ( errorAPI ) ? errorAPI : error;
        let content = ( errorCont ) ? errorCont : this.render_regular_mode();

        return (
            <div className={ 'App ts ts-ease-in ts-slow grad-back regular-mode' +  errorClass }>
                <Router>
                    { content }
                </Router>
            </div>
        );
    }

}

export default App;