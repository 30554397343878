import { setup } from '../config/'

export function getRemoteSocket( that, token ) {
    var webSocket = new WebSocket( setup.remoteSocket + '?token=' + token );
    webSocket.onopen = () => {
        console.log( '.:: RMT webSocket ::: onOpen ::.' );
        that.setState( { WSReady : true });
    }
    webSocket.onclose = () => {
        console.log( '.:: RMT webSocket ::: onClose ::.' );
        that.setState( { WSReady : false });
    }
    webSocket.onerror = ( err ) => {
        that.setState({ WSError : true });
        console.log( '.:: RMT webSocket webSocket ::: onError ::.' );
        webSocket.close();
    };
    return webSocket;
}

export function forceReloadSendMessage( that, mode ) {
    const { webSocket } = that.props
    var data = {
        'jsonrpc'   : '2.0',
        'id'        : Date.now(),
        'method'    : 'control_showContent',
        'params'    : [ 'forceReloadApp', [ mode ] ]
    }
    console.log('forceReloadSendMessage > data', data );
    webSocket.send( JSON.stringify( data ) )
}

export function forceReloadScreenSendMessage( that, mode ) {
    const { webSocket } = that.props
    var data = {
        'jsonrpc'   : '2.0',
        'id'        : Date.now(),
        'method'    : 'control_showContent',
        'params'    : [ 'forceReloadScreen', [ mode ] ]
    }
    webSocket.send( JSON.stringify( data ) )
}


export function displayIconSendMessage( that, mode ) {
    const { webSocket } = that.props
    var data = {
        'jsonrpc'   : '2.0',
        'id'        : Date.now(),
        'method'    : 'control_showContent',
        'params'    : [ 'displayInfo', [ mode ] ]
    }
    console.log('displayIconSendMessage > data', data );
    webSocket.send( JSON.stringify( data ) )
}



export function getDisplaySocket( that, token ) {

    var webSocket = new WebSocket( setup.displaySocket + '?token=' + token );

    webSocket.onopen = () => {
        console.log( '.:: DSP webSocket ::: onOpen ::.' );
    }

    webSocket.onmessage = ( evt ) => {
        const message = JSON.parse( evt.data );
        console.log( '.:: DSP webSocket ::: onMessage ::.', evt.data );
        
        that.setState( { lastSockewtMS : message } );
        
        if ( message.method === 'screenFeedBack' ) {
            that.setState( { isScreenReady : message.params[0] } );
            that.refreshScreen( false );
        } else if ( message.method === 'forceReloadApp' ) {
            that.refreshPage( message.params[0] );
        } else if ( message.method === 'forceReloadScreen' ) {
            that.refreshScreen( message.params[0] );
        }
    }

    webSocket.onclose = () => {
        console.log( '.:: DSP webSocket ::: onClose ::.' );
    }

    webSocket.onerror = ( err ) => {
        that.setState({ WSError : true });
        console.log( '.:: DSP webSocket webSocket ::: onError ::.' );
        webSocket.close();
    };
 
    return webSocket;

}