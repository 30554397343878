import React, { Component } from 'react';
import { TopSimpleHeader } from '../../organisms';
import { forceReloadSendMessage, forceReloadScreenSendMessage, displayIconSendMessage } from '../../../WS/'
import './style.css';


class ReloadPage extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            load        : false,
            APPNoResp   : false,
            SCNoResp    : false,
            DSPIcon     : false,
            lastActions : []
        };
        this.forceReloadApp = this.forceReloadApp.bind( this );
        this.forceReloadScreen = this.forceReloadScreen.bind( this );
        this.displayIcon = this.displayIcon.bind( this );
    }
    
    forceReloadApp() {
        forceReloadSendMessage( this, true );
        this.setState( { APPNoResp : false } )
        var that = this;
        setTimeout( function() {
            if ( that.props.mustReload ) {
                that.setState( { APPNoResp : true } )
            }
        }, 15000 );
    }

    forceReloadScreen() {
        forceReloadScreenSendMessage( this, true );
        this.setState( { SCNoResp : false } )
        var that = this;
        setTimeout( function() {
            if ( that.props.mustReload ) {
                that.setState( { SCNoResp : true } )
            }
        }, 15000 );
    }
    
    displayIcon() {
        this.setState({ DSPIcon : !this.state.DSPIcon }, () => { displayIconSendMessage( this, this.state.DSPIcon ) } );
        
    }

    syntaxHighlight(json) {
        if (typeof json != 'string') {
             json = JSON.stringify(json, undefined, 2);
        }
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            var cls = 'number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'key';
                } else {
                    cls = 'string';
                }
            } else if (/true|false/.test(match)) {
                cls = 'boolean';
            } else if (/null/.test(match)) {
                cls = 'null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
        });
    }
    
    componentDidUpdate( prevProps, prevState ) {
        if ( prevProps.lastSockewtMS !== this.props.lastSockewtMS ) {
            this.setState({ 
                lastActions : [ ...this.state.lastActions, this.syntaxHighlight( JSON.stringify( this.props.lastSockewtMS ) ) ].slice(-5) 
            });
        }
    }

    
    render() {
        
        console.log( this.props.lastSockewtMS );
        
        var loadClass = ( this.props.WSReady ) ? 'loaded' : 'fetching'; 
        var pendingApp = ( this.props.mustReload ) ? <p>Waiting for iPad Reload</p> : <p>No iPad Reload Pending...</p>
        var pendingScreen = ( this.props.mustReloadSC ) ? <p>Waiting for Screens Reload</p> : <p>No Screens Reload Pending...</p>
        var pendingApp = ( !this.state.APPNoResp ) ? pendingApp : <p>iPad is not responding<br />please, check is open</p>
        var pendingScreen = ( !this.state.SCNoResp ) ? pendingScreen : <p>Screens are not responding<br />please, check are open</p>
        var appNtnLabel = ( !this.state.APPNoResp ) ? 'Reload now' : 'Try again'
        var csNtnLabel = ( !this.state.SCNoResp ) ? 'Reload now' : 'Try again'
        
        return(
            <div className={ 'page reload-page ' + loadClass }>
                <TopSimpleHeader />
                <div className={ 'supersymetric ts ts-slow ts-ease-out outbox' }>
                    <div className={ 'box' }>
                        <div className={ 'box-left' }>
                            <h3>Reload iPad APP</h3>
                            { pendingApp }
                        </div>
                        <div className={ 'box-right' }>
                            <button onClick={ this.forceReloadApp }>{ appNtnLabel }</button>
                        </div>
                    </div>
                    <div className={ 'box' }>
                        <div className={ 'box-left' }>
                            <h3>Reload Screens APP</h3>
                            { pendingScreen }
                        </div>
                        <div className={ 'box-right' }>
                            <button onClick={ this.forceReloadScreen }>{ csNtnLabel }</button>
                        </div>
                    </div>
                    <div className={ 'box' }>
                        <div className={ 'box-left' }>
                            <h3>Who is listening?</h3>
                            <p>Show WS and API info on devices</p>
                        </div>
                        <div className={ 'box-right' }>
                            <button onClick={ this.displayIcon }>Show on devices</button>
                        </div>
                    </div>
                    <div className={ 'tty' }> 
                        <div dangerouslySetInnerHTML={ { __html: this.state.lastActions[0] } } />
                        <div dangerouslySetInnerHTML={ { __html: this.state.lastActions[1] } } />
                        <div dangerouslySetInnerHTML={ { __html: this.state.lastActions[2] } } />
                        <div dangerouslySetInnerHTML={ { __html: this.state.lastActions[3] } } />
                        <div dangerouslySetInnerHTML={ { __html: this.state.lastActions[4] } } />
                    </div>
                </div>
            </div>
        )
    }
}

export default ReloadPage;