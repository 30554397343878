import { get, API_PREFIX, REMOTE_TOKEN, DISPLAY_TOKEN } from './utils'

export function getWebSocketTokenRemote() {
    const url = REMOTE_TOKEN;
    return get( url );
}

export function getWebSocketTokenDisplay() {
    const url = DISPLAY_TOKEN;
    return get( url );
}